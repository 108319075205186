@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700,500|Roboto+Condensed:400,700);

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

html * {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
